$.lui("comp",".comp_menu",function (selector) {
    if(selector.length) {
        let nav = selector.find(".elm_comp_nav");
        let navActive = nav.find(".state--active");

        nav[0].scrollLeft = navActive[0].offsetLeft - 100;

        nav.on("click",".elm_nav_item",function () {
            let elm = $(this);

            nav[0].scrollLeft = elm[0].offsetLeft - 100;
        });
    }
});