function cssLoaded(callback) {
    if (document.querySelector("[data-loadcss]") !== null) {
        let wait = setInterval(function(){
            if (getComputedStyle(document.body)["margin-left"] === "0px") {
                clearInterval(wait);
                callback();
            }
        },1);
    } else {
        callback();
    }
}