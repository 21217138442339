$.lui("comp",".comp_visual",function (selector) {
    let code = selector.find("[data-code-input=1]");
    let input;

    selector.one("click","[data-lib-tippy]",function () {
        let tippy = $(document).find("#tippy-1")[0]._tippy;
        input = ($(tippy.popper).find(".part_ui_input").find("input"));

        doc.on("click","[data-code-submit]",function () {
            code.val(input.val());
            tippy.hide();
        })
    });

    doc.on("click","[data-code-toggle]",function () {
        selector.find("[data-code-input]").each(function () {
            $(this).val("");
        });
        switch ($(this).data("code-toggle")) {
            case 1: {
                code = selector.find("[data-code-input=1]");
                break;
            }
            case 2: {
                code = selector.find("[data-code-input=2]");
                break;
            }
            case 3: {
                code = selector.find("[data-code-input=3]");
                break;
            }
            default: {
                break;
            }
        }

        input.focus();
    });
});