$.lui("comp",".comp_faq",function (selector) {
    selector.on("click",".part_item_faq .wrp_part_head",function () {
        let el = $(this).closest(".part_item_faq"),
            part = el.find(".wrp_part_body");
        if(el.is(".state--active")) {
            el.removeClass("state--open");
            part.stop().slideUp(function(){
                el.removeClass("state--active");
            });
        }
        else {
            el.addClass("state--active state--open");
            part.stop().slideDown(function(){
            });
        }
    })
});