//____ ANCHOR LINKS ANIMATIONS ____ //
//____ AUTHOR: LUBOMÍR BLAŽEK ____ //
(function ($) {
    let selector = $("[data-lib-anchor-id]");

    let hash = window.location.hash;

    $.fn.anchor_anim = function (duration) {
        let elm = $(this),
            offset = 0;

        if (elm.filter("[data-lib-anchor-offset]").length && win.width() > 960) {
            if (isNaN(elm.data("lib-anchor-offset"))) {
                offset = $(elm.data("lib-anchor-offset")).innerHeight();
            } else {
                offset = elm.data("lib-anchor-offset");
            }
        }

        $('html, body').animate({
            scrollTop: elm.offset().top - offset
        }, duration);
    };

    doc.on("click", "[data-lib-anchor]", function(e) {
        let elm = $(this);
        let id = elm.attr("href");
        let options = elm.data("lib-anchor");

        if (elm.filter("[data-lib-anchor-href]").length) {
            id = elm.data("lib-anchor-href");
        }

        let anchor_elm = $("[data-lib-anchor-id="+id.replace('#','')+"]");

        if (anchor_elm.length) {
            e.preventDefault();

            if (!(options.indexOf("mobile") > -1 && win.width() > 960)) {
                anchor_elm.anchor_anim(500);

                if(options.indexOf("hash") > -1) {
                    window.location.hash = id;
                }
            }
        }
    });
        
    if(selector.length) {

        selector.each(function(){
            let elm = $(this);

            if(hash && elm.data("lib-anchor-id") === hash.replace('#','')) {
                cssLoaded(function(){
                    elm.anchor_anim(0);
                });
            }
        });
    }
})(jQuery);