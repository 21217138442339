$.fn.lib_map = function (data) {
    let selector = $(this);
    $.importScript(cdnjs.googlemap.replace("APIKEY", data["api"]), function(){
        cssLoaded(function(){
            let zoom = data["zoom"],
                coords = data["coords"].split(/[\s,]+/),
                marker = data["marker"],
                markers = data["markers"],
                markers_data;

            if(typeof markers !== "undefined") {
                markers_data = data["markers"]["data"];
            }

            let map = new google.maps.Map(selector[0], {
                zoom: zoom,
                center: {
                    lat: parseFloat(coords[0]), lng: parseFloat(coords[1])
                },
                mapTypeControl: false,
                styles: [
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#3e5629"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#3e5629"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#cee0c0"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#04234d"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.neighborhood",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#04234d"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape",
                        "stylers": [
                            {
                                "color": "#f2f2f2"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#e7f0e0"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#cee0c0"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural.terrain",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#e7f0e0"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#afcd97"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.business",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#d6e5ca"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#afcd97"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#40618a"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.place_of_worship",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#c8dff3"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.school",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#c1d9ae"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.sports_complex",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#c2d9b0"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.sports_complex",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#445f2e"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "stylers": [
                            {
                                "saturation": -100
                            },
                            {
                                "lightness": 45
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#40618a"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "stylers": [
                            {
                                "visibility": "simplified"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#fafcf8"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#40618a"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#ffffff"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "stylers": [
                            {
                                "color": "#8ebee6"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    }
                ]
            });

            let bounds = new google.maps.LatLngBounds();

            let map_marker_position = new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]));

            let map_marker = new google.maps.Marker({
                position: map_marker_position,
                map: map,
                icon: {
                    url: marker["url"],
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(marker["width"]/2, marker["height"])
                },
                title: marker["title"]
            });

            let infoWindow = new google.maps.InfoWindow();

            google.maps.event.addListener(map_marker, 'click', function(){
                window.open(marker["link"], '_blank');
            });

            bounds.extend(map_marker_position);

            if (selector.is("[data-lib-directions]")) {
                let directionsService = new google.maps.DirectionsService;
                let directionsDisplay = new google.maps.DirectionsRenderer({
                    polylineOptions: {
                        strokeColor: "#295135",
                        strokeWeight: "7"
                    },
                    markerOptions: {
                        icon: {
                            url: "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNS42NzYiIGhlaWdodD0iMzIuMTYyIiB2aWV3Qm94PSIwIDAgMjUuNjc2IDMyLjE2MiI+DQogIDxnIGlkPSJHcm91cF80OTQiIGRhdGEtbmFtZT0iR3JvdXAgNDk0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjM5IC00NzgzLjUpIj4NCiAgICA8cGF0aCBpZD0ibWVldGluZy1tYXJrZXItNzU2IiBkPSJNMjAuODY1LDJBMTIuODY1LDEyLjg2NSwwLDAsMCw4LDE0Ljg2NSw5LjA4NSw5LjA4NSwwLDAsMCw4LjA1NCwxNi4xdi4xNjFjMS4wNzIsMTAuMTg1LDEyLjgxMSwxNy45LDEyLjgxMSwxNy45UzMyLjYsMjYuMzM2LDMzLjYyMiwxNi4zMTJWMTYuMWE5LjQ4OSw5LjQ4OSwwLDAsMCwuMDU0LTEuMjMzQTEyLjg2NSwxMi44NjUsMCwwLDAsMjAuODY1LDJaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2MzEgNDc4MS41KSIgZmlsbD0iIzI5NTEzNSIvPg0KICAgIDxjaXJjbGUgaWQ9IkVsbGlwc2VfMiIgZGF0YS1uYW1lPSJFbGxpcHNlIDIiIGN4PSI0IiBjeT0iNCIgcj0iNCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjQ4IDQ3OTIpIiBmaWxsPSIjZmZmIi8+DQogIDwvZz4NCjwvc3ZnPg0K",
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(16, 26)
                        }
                    },
                });

                let form = selector.closest(".comp_location_map").find("[data-directions]");

                directionsDisplay.setMap(map);

                form.on("submit",function (e) {
                    e.preventDefault();
                    let settings = form.serializeArray().reduce((acc, {name, value}) => ({...acc, [name]: value}),{});

                    directionsService.route({
                        origin: map_marker_position,
                        destination: settings.destination,
                        travelMode: google.maps.TravelMode[settings.type]
                    }, function(response, status) {
                        if (status === 'OK') {
                            directionsDisplay.setDirections(response);
                        } else {
                            console.log('Directions request failed due to ' + status);
                        }
                    });
                });

            }

            if (typeof markers_data !== "undefined") {
                markers_data.forEach(function(item){
                    let coords = item["coords"].split(/[\s,]+/);
                    let position = new google.maps.LatLng(parseFloat(coords[0]), parseFloat(coords[1]));

                    let marker = new google.maps.Marker({
                        position: position,
                        map: map,
                        icon: {
                            url: markers["url"],
                            scaledSize: new google.maps.Size(markers["width"], markers["height"]),
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(markers["width"]/2, markers["height"])
                        },
                        title: item["title"]
                    });

                    function showInfo(marker, item) {
                        return function () {
                            map.panTo(marker.position);
                            infoWindow.setContent(`
                                <div class="gm-custom-iw-inner">
                                    <div class="elm_title">${item.title}</div>
                                    <div class="elm_text">${item.text}</div>
                                </div>
                            `);
                            infoWindow.open(map,marker);
                        }
                    }

                    google.maps.event.addListener(marker, 'click', showInfo(marker, item));

                    google.maps.event.addListener(infoWindow, 'domready', function() {
                        selector.find('.gm-custom-iw-inner').closest('.gm-style-iw').parent().addClass('gm-custom-iw');
                    });

                    bounds.extend(position);
                });

                google.maps.event.addListenerOnce(map, 'bounds_changed', function() {
                    if(this.getZoom() > zoom) {
                        this.setZoom(zoom);
                    }
                });

                map.fitBounds(bounds);
            }
        });
    });
};