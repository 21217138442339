$.fn.lib_pdf = function (data) {
    $(this).each(function () {
        let elm = $(this)[0];
        let inView = false

        if (typeof IntersectionObserver === 'undefined') {
            createPDF(elm, data)
            return false
        }

        new IntersectionObserver(
            entries => {
                if (entries[0].isIntersecting && inView === false) {
                    inView = entries[0].isIntersecting
                    createPDF(elm, data)
                }
            }, {
                rootMargin: '100px'
            }
        ).observe(elm)
    })

    function createPDF(element, data) {
        $.importScript("/pdfjs/build/pdf.js", () => {
            const pdfjsLib = window['pdfjs-dist/build/pdf'];

            pdfjsLib.GlobalWorkerOptions.workerSrc = '/pdfjs/build/pdf.worker.js';

            const loadingTask = pdfjsLib.getDocument(data.src);
            loadingTask.promise.then(function(pdf) {
                const pageNumber = 1;
                pdf.getPage(pageNumber).then(function(page) {
                    const scale = 1.5;
                    const viewport = page.getViewport({scale: scale});

                    const canvas = element.querySelector('[data-lib-pdf-target="canvas"]')
                    const context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    const renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    const renderTask = page.render(renderContext);
                    renderTask.promise.then(function () {

                    });
                });
            }, function (reason) {
                console.error(reason);
            });
        })
    }
}
