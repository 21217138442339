$.fn.lui_tabs = function (callback) {
    $(this).each(function () {
        let elm = $(this);

        let nav = '[data-lib-tabs-nav="'+elm.find("[data-lib-tabs-nav]").first().data("lib-tabs-nav")+'"]',
            area = '[data-lib-tabs-area="'+elm.find("[data-lib-tabs-area]").first().data("lib-tabs-area")+'"]',
            nav_item = `[data-lib-tabs-item="nav"]`,
            area_item = `[data-lib-tabs-item="area"]`;

        $.fn.nl_lib_tabs_click = function (n, parent) {
            $(this).on("click", function (e) {
                e.preventDefault();
                $(this).addClass("state--active").closest(nav).find(nav_item).not(this).removeClass("state--active");

                if (parent === nav) {
                    $(this).closest(elm).find(area).children(nav_item).removeClass("state--active").eq(n).addClass("state--active");
                } else {
                    $(this).closest(elm).find(nav).find(nav_item).removeClass("state--active").eq(n).addClass("state--active");
                }

                $(this).closest(elm).find(area).each(function() {
                    $(this).children(area_item).hide().removeClass("state--active").eq(n).show().addClass("state--active");

                    if (callback) {
                        callback(n);
                    }
                });
            });
        };

        elm.find(nav).first().find(nav_item).each(function(n) {
            $(this).nl_lib_tabs_click(n,nav);
        });
        elm.find(area).first().children(nav_item).each(function(n) {
            $(this).nl_lib_tabs_click(n,area);
        });
    });
};