$.fn.lui_tippy = function (attributes, onShow, onHide) {
    $(this).each(function(){
        let elm = $(this);
        let data = elm.data(attributes).replace(/\s/g,"").split(",");
        let content;
        let trigger = "mouseenter focus";
        let placement = "top";
        let arrow = true;
        let cursor = false;
        let distance = 10;
        let interactive = true;
        let template;

        if (typeof data[1] !== "undefined") {
            template = $('#' + data[1]);
            content = template.html();
        } else {
            content = `
                <div class="part_ui_dropdown">
                    <div class="wrp_ui_body">
                        ${elm.attr("aria-label")}
                    </div>
                </div>
            `;
            interactive = false;
        }

        if (typeof data[0] !== "undefined") {
            if (data[0].includes("dropdown")) {
                trigger = "click";
                if (data[0].includes("dropdown-")) {
                    placement = data[0].replace("dropdown-", "").replace(/\d+/,"");
                    if (data[0].match(/\d+/)) {
                        distance = parseInt(data[0].match(/\d+/)[0]);
                    }
                }
            } else if (data[0].includes("tooltip")) {
                if (data[0].includes("tooltip-")) {
                    placement = data[0].replace("tooltip-", "")
                    arrow = false;
                    interactive = false;
                }
            } else if (data[0].includes("cursor-")) {
                placement = data[0].replace("cursor-", "")
                cursor = "horizontal";
                interactive = false;
            } else {
                placement = data[0];
            }
        }

        if (typeof data[2] !== "undefined") {
            content = content.replace("{this}",data[2]);
        }

        tippy(elm[0], {
            content: content,
            placement: placement,
            theme: 'light-border',
            trigger: trigger,
            interactive: interactive,
            arrow: arrow,
            arrowType: 'round',
            animation: "scale",
            followCursor: cursor,
            flip: true,
            inertia: true,
            distance: distance,
            onShow(instance) {
                $(instance.popper).children(".tippy-tooltip").attr("data-name",data[1]);

                if (onShow) {
                    onShow(instance);
                }
            },
            onHide(instance) {
                if (onHide) {
                    onHide(instance);
                }
            }
        });
    });
};