let cdnjs = {
    "headroom": "https://cdn.jsdelivr.net/npm/headroom.js@0.9.4/dist/headroom.min.js",
    "touchswipe": "https://cdn.jsdelivr.net/npm/jquery-touchswipe@1.6.19/jquery.touchSwipe.min.js",
    "picturefill": "https://cdn.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "flickity": "https://cdn.jsdelivr.net/npm/flickity@2.2.0/dist/flickity.pkgd.min.js",
    "fancybox": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://cdn.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css",
    "googlemap": "https://maps.googleapis.com/maps/api/js?key=APIKEY",
    "recapcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "datepicker": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/datepicker.min.js",
    "rellax": "https://cdn.jsdelivr.net/npm/rellax@1.10.0/rellax.min.js",
    "datepicker_lang": "https://cdn.jsdelivr.net/npm/air-datepicker@2.2.3/dist/js/i18n/datepicker.cs.min.js"
};