(function() {
    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().lui_parallax();
        }
    });

    lui_dialog.init(lui_dialog_callback);

    $.lui("lib_parallax", ".lib--parallax", function(selector) {
        cssLoaded(function(){
            selector.find("img:not(.lazyload)").parent().lui_parallax();
        });
    });

    $.lui("lib_reveal", "[data-lib-reveal]", function() {
        $.lib_reveal();
    });

    $.lui("lib_ripple", ".part_ui_link, .part_ui_btn", function(selector) {
        selector.lui_ripple();
    });

    $.lui("lib_switch", "[data-lib-tabs]", function(selector) {
        selector.lui_tabs();
    });

    $.lui("lib_flickity", "[data-lib-flickity]", function(selector) {
        selector.lib_flickity();
    });

    $.lui("lib_map", "[data-lib-map]", function(selector){
        if (selector.data("lib-load") === true) {
            selector.lib_map(selector.data("lib-map"));
        }
    });

    $.lui("lib_pdf", "[data-lib-pdf]", function(selector){
        selector.lib_pdf(selector.data("lib-pdf"));
    });

    $.lui("lib_gallery", "[data-lib-fancybox]", function(selector) {
        bodyLoaded(function(){
            if (selector.find("[data-lib-fancybox-item]").length) {
                $.importStyle(cdnjs.fancybox_css);
                $.importScript(cdnjs.fancybox, function(){
                    selector.each(function(){
                        let id = $(this).data("lib-fancybox");
                        $(this).fancybox({
                            selector: `[data-lib-fancybox="${id}"] [data-lib-fancybox-item]`,
                            transitionEffect: "slide",
                            animationEffect: "zoom",
                            hideScrollbar: false,
                            buttons: [
                                "close"
                            ]
                        });
                    });
                });
            }
        });
    });

    $.lui("lib_rellax", "[data-lib-rellax]", function(selector) {
        if ($(selector).length && win.width() > 960) {
            $.importScript(cdnjs.rellax, function() {
                bodyLoaded(function(){
                    selector.each(function(){
                        new Rellax($(this)[0], {
                            center: $(this).data("lib-rellax") === "center"
                        });
                    });
                });
            });
        }
    });

    $.lui("lib_tippy", "[data-lib-tippy]", function(selector) {
        selector.lui_tippy("lib-tippy");
    });

    $.lui("lib_anchor", "[data-lib-anchor-id]", function(selector) {
        let hash = window.location.hash;

        $.fn.anchor_anim = function (duration) {
            let elm = $(this),
                offset = 0;

            if (elm.filter("[data-lib-anchor-offset]").length && win.width() > 960) {
                if (isNaN(elm.data("lib-anchor-offset"))) {
                    offset = $(elm.data("lib-anchor-offset")).innerHeight();
                } else {
                    offset = elm.data("lib-anchor-offset");
                }
            }

            $('html, body').animate({
                scrollTop: elm.offset().top - offset
            }, duration);
        };

        if(selector.length) {
            if(hash && selector.data("lib-anchor-id") === hash.replace('#','')) {
                cssLoaded(function(){
                    selector.anchor_anim(0);
                });
            }
        }
    });

    doc.on("click","[data-scroll]",function () {
       $(this).closest("section").next("section, div").anchor_anim(500);
    });

    (function fn_ajaxEvents() {
        doc.on('click', '[href].ajax', function (e) {
            e.preventDefault();
            let el = $(this);

            $.ajax({
                url: el.attr('href'),
                data: {ajax: +new Date},
                dataType: "json",
            }).done(function(payload){
                fn_ajaxHandler(payload);
            });
        });

        doc.on('submit', 'form.ajax,form[data-component-ajax]', function (e) {
            e.preventDefault();
            let frm = $(this),
                formData = new FormData($(this)[0]);

            frm.find('button[type=submit]').addClass('state--loading').attr('disabled', true);

            $.ajax({
                method: frm.attr('method'),
                url: frm.attr('action'),
                data: formData,
                processData: false,
                contentType: false,
                dataType: "json",
            }).done(function(payload){
                fn_ajaxHandler(payload);
            }).always(function(){
                frm.find('button[type=submit]').removeClass('state--loading').attr('disabled', false);
            });
        });

        doc.on('click', 'a[data-component-ajax]', function (e) {
            e.preventDefault();
            let url = $(this).attr('data-href');
            if (!url || !url.length) {
                url = $(this).attr('href');
            }

            if (!url || !url.length) {
                console.log('no href attribute for ajax component link');
                return;
            }

            $.ajax({
                url: url,
                method: 'post',
                dataType: 'json'
            }).done(function (payload) {
                fn_ajaxHandler(payload, function () {
                    ScrollReveal().sync();
                });
            });
        });
    })();

    (function fn_pictureFill() {
        if (document.createElement('picture').toString().indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie")) {
            $.importScript(cdnjs.picturefill);
        }
    })();

    (function fn_gridFill() {
        if (typeof html[0].style.grid !== 'string') {
            html.addClass("no-grid");
        }
    })();

    (function fn_cookieConsent() {
        if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
            $.importScript(cdnjs.cookieconsent);
        }
    })();

    $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
        selector.lib_reCaptcha("lib-recaptcha");
    });
    setInterval(function(){
        $.lui("lib_reCaptcha", "[data-lib-recaptcha]", function(selector) {
            selector.lib_reCaptcha("lib-recaptcha");
        });
    }, 150000);

})();
