$.lui("part",".part_menu",function (selector) {
    selector.on("click","[data-action=\"toggleTooltip\"]",function () {
        let tooltip = $(this).closest(".elm_item_tooltip");

        tooltip.closest(selector).find(".elm_item_tooltip").not(tooltip).removeClass("state--active");
        tooltip.addClass("state--active");

        setTimeout(function () {
            doc.one("click",function () {
                tooltip.removeClass("state--active");
            })
        },350);

    });
});