$.lui("layout","#layout_header", function(selector) {
    $.importScript(cdnjs.headroom, function(){
        let headroom = new Headroom(selector[0], {
            offset: 5,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();
    });
});

$.importScript(cdnjs.touchswipe, function() {
    $('.ssm-nav').lui_ssm();
});